@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

@font-face {
    font-family: 'Roboto-Regular';
    src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-SemiBold';
    src: url('assets/fonts/Roboto-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-ExtraBold';
    src: url('assets/fonts/Roboto-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}